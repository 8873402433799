<template>
<div class="mb-4">
    <Subtitle title="Betalning"></Subtitle>
    <Loader v-if="loading" cols="3" rows="3"></Loader>
    <div v-else>
        <div v-if="isFree">
            <Alert variant="info" >Gratisturnering!</Alert>
            <h5>Kostnad: GRATIS!</h5>
            <p>Att vara med och tävla i {{tournament.name}} är helt gratis och ingen kostnad kommer att ske!<br>
                Detta innebär också att det inte finns några vinstpengar i turneringen.</p>
        </div>
        <div v-else>
            <h5>Kostnad: {{cost}} SEK</h5>
            <p>
                Att vara med och tävla i {{tournament.name}} kostar <strong>{{cost}} kr</strong> per medlem. Betalning ska ske senast <strong>{{payeddate}}</strong>.
            </p>
            <div>
                <span v-html="description"></span>
            </div>
            <br>
            <b-row class="my-3" no-gutters>
                <b-col cols="12" md="6">
                    Betalning kan ske på följande sätt
                    <ul>
                        <li>Via Swish</li>
                        <li>Direkt till mig i handen</li>
                        <li>Till bankkonto</li>
                    </ul>
                    <p @click="ShowPaymentSwish()" class="mt-1 btn btn-link">Gör såhär för att betala med QR-koden i mobilen.</p>

                </b-col>
                <b-col cols="12" md="6" class="text-center">
                    <span v-html="payment.qr"></span>
                    <div class="w3-small w3-center"><i>Swish-QR</i></div>
                </b-col>
            </b-row>
            Följ instruktionerna nedan för att betala.
            <br>
            <b-row no-gutters class="my-3">
                <b-col cols="12" class="font-weight-bold">Ange</b-col>
                <b-col>Din registrerade e-post eller ditt användarnamn</b-col>
                <b-col cols="12" class="font-weight-bold">SWISH</b-col>
                <b-col>{{payment.swish}} (OBS EXAKT)</b-col>
                <b-col cols="12" class="font-weight-bold">Bank</b-col>
                <b-col>{{payment.bank}}</b-col>
                <b-col cols="12" class="font-weight-bold">Kontonummer</b-col>
                <b-col>{{payment.account}} <span class="font-italic" v-if="payment.type">({{payment.type}})</span></b-col>
                <b-col cols="12" class="font-weight-bold">Clearingnummer</b-col>
                <b-col>Använd det clearing din bank anger i sin information</b-col>

            </b-row>
        </div>
    </div>
    <b-modal size="lg" v-model="swishmodal" footer-class="justify-content-center text-center" ok-only ok-title="stäng" title="Instruktioner Swish">
        <Swish></Swish>
    </b-modal>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Subtitle = () => import("@/components/common/Subtitle");
const Swish = () => import("@/components/dashboard/SwishInstructions");

export default {
    name: 'DashboardPayment',
    components: {
        Alert,
        Loader,
        Subtitle,
        Swish
    },
    data() {
        return {
            swishmodal: false,
        };
    },
    computed: {
        isFree() {
            var p = this.payment;
            if (p && p.amount > 0)
                return false;
            return true;
        },
        tournament() {
            return this.$store.getters["tournamentsg/tournament"];
        },
        payment() {
            var data = this.$store.getters["dashboard/dashboard"];
            if (data && data.payment)
                return data.payment;
            return [];
        },
        loading() {
            return this.$store.getters["dahsboard/loading"];
        },
        cost() {
            var total = parseInt(this.payment.amount);
            if (this.payment.extra)
                total += parseInt(this.payment.extra);
            return total;
        },
        payeddate() {
            return this.payment.date;
        },
        description() {
            var amount = parseInt(this.payment.amount);
            var extra = 0;
            if (this.payment.extra)
                extra = parseInt(this.payment.extra);
            var af = this.$formatter.formatCurrency(amount, 0);
            var ef = this.$formatter.formatCurrency(extra, 0);
            if (extra > 0) {
                return "Grundkostnaden är <strong>" + af + "</strong> för att delta och kunna vinna prispengar.<br>Vill man kunna <strong>se andra användares tips</strong> innan matcherna är påbörjade kostar det istället <strong>" + ef + "</strong> men ger alltså fördelen att se vad andra har valt. Dessa extra " + ef + " går att betala under hela turneringens gång och möjligheten att se andras tips öppnas när betalningen registreras (fördelaktigt med swish).";
            } else {
                return "Alla användare betalar <strong>" + ef + "</strong> för att delta och kunna vinna prispengar. Betalar man inte kan man fortfarande vara med i turneringen men alltså utan vinst. Det går inte att betala efter sista datum.";
            }
        },
    },
    methods: {
        ShowPaymentSwish() {
            this.swishmodal = true;
        }
    }
};
</script>
